import './Login.css';
import { useHWAppData } from "./HWAppDataProvider";

function Login() {

  const { HWData, setHWData } = useHWAppData();
  
  const sendLogin = (event) => {
    event.preventDefault();
    
    if (!document.getElementById('floatingInput').value.match(/\w@\w/)) {
      alert('Please use a deliverable email address as your login');
      return false;
    }
    if (!document.getElementById('floatingPassword').value.match(/\w\w\w\w\w\w/)) {
      alert('Please enter your password');
      return false;
    }   
  
    setHWData({ ...HWData, 'loading' : true });
    
    fetch("https://6rqtwb8h2f.execute-api.us-east-1.amazonaws.com/0/auth", {
      method : "POST",
      body: JSON.stringify({
        email : HWData.userEmail, 
        password : HWData.userPassword
      })
    })
    .then((res) => {
      if (401 === res.status) { 
        alert('Incorrect login credentials'); 
        setHWData({ 
          userEmail : "",
          userPassword : "",
          token : null,
          loading : false,
          page : "login"        
        });
      }
      else if (200 === res.status) { 
        res.json().then((data) => {
          setHWData({ ...HWData, page: 'home', token: data.token, userPassword: "_confirmed_" });
        })
        .catch((err) => console.log(err));
      }
    })
    .catch((err) => console.error(err));
    
  };
  
  const formAction = (event) => {
    const key = event.target.getAttribute('name');
    const val = event.target.value;
    setHWData({ ...HWData, [key]: val });
  };
  
  
  return (
  <div className="text-center">   
    <main className="form-signin">
      <form onSubmit={sendLogin}>
        <h1 className="h3 mb-3 fw-normal">Please sign in</h1>

        <div className="form-floating">
          <input type="email" value={HWData.userEmail} className="form-control" name="userEmail" id="floatingInput" placeholder="name@example.com" onChange={formAction} onBlur={formAction} required />
          <label htmlFor="floatingInput">Email address</label>
        </div>
        <div className="form-floating">
          <input type="password" value={HWData.userPassword} className="form-control" name="userPassword" id="floatingPassword" placeholder="Password" onChange={formAction} onBlur={formAction} required />
          <label htmlFor="floatingPassword">Password</label>
        </div>
        
        <button onClick={sendLogin} className="w-100 btn btn-lg btn-primary" type="submit">Sign in</button>
      </form>
    </main>
  </div>  
  );
  
}

export default Login;