import HWAppDataProvider, { useHWAppData } from "./HWAppDataProvider";
import LoadingLogin from "./LoadingLogin";
import Login from "./Login";
import Home from "./Home";

  


function Main() {
  const { HWData, setHWData } = useHWAppData();
  
  const getMembers = () => {

    let url = "https://6rqtwb8h2f.execute-api.us-east-1.amazonaws.com/0/members?email=" + HWData.userEmail;
  
    fetch( url, {
      method : "GET",
      cache : "no-cache",
      headers : {
        Authorization : HWData.token
      }
    })
    .then((res) => {
      if (401 === res.status) {
        alert("You're authentication has expired. Please login again.");
        setHWData({page: "login", token: null, userEmail: "", userPassword : "", loading: false});
      }
      else if (200 === res.status) {
        res.json().then((data) => {
          setHWData({
            ...HWData, 
            combined : [
              ...data.people,
              ...data.groups
            ],
            people : data.people,
            groups : data.groups, 
            current : [
              ...data.people,
              ...data.groups
            ],
            deliverableOnly : false,
            chosen : "combined",
            loading : false,
            search : "",
            donations : data.donations
          }); 
        })
        .catch((err) => {
          console.log(err);
        })
      }
    })
    .catch((err) => {});
  }
   
  if (["home", "people", "groups", "entry", "edit", "newContact", "donations"].includes(HWData.page)) {
    if ("undefined" === typeof(HWData.combined) || !HWData.combined) {
      getMembers();    
    }
    return <Home />;
  }
  else if (HWData.loading) {
    return <LoadingLogin />;
  }
  else if ("login" === HWData.page) {
    return <Login />;
  } 
}


function App() {
  return (
    <HWAppDataProvider>
      <Main />
    </HWAppDataProvider>
  );
}

export default App;
