import { useHWAppData } from "./HWAppDataProvider";
import {v4} from "uuid";

function EditDonations() {
  const { HWData, setHWData } = useHWAppData();
  
  const user_id = HWData.current[HWData.edit].id;
  
  const updateDonation = (event) => {
    const updated = HWData.donations[user_id].map((donation) => {
      if (donation.id === event.target.dataset.id) {
        return { ...donation, [event.target.dataset.key] : event.target.value };
      }
      else {
        return donation;
      }
    });
    
    const user_donations = { ...HWData.donations, [user_id] : updated };
        
    setHWData({ ...HWData, donations: user_donations, page: "donations" });
  };

  const newDonationId = () => {
    const id   = v4();
    const used = Object.values(HWData.donations).reduce((prev, curr) => {
      if (prev) { return prev; }
      else {
        return curr.reduce((p, c) => (p || c.id === id), false);
      }
    }, false);
    
    if (used) {
      return newDonationId();
    }
    else {
      return id;
    }
  };
  
  const deleteDonation = (event) => {
    if (window.confirm("Permanently delete this donation entry?")) {

        // set screen to "laoding" gif
        setHWData({ ...HWData, page: "home", search: "", loading: true});

        fetch( "https://6rqtwb8h2f.execute-api.us-east-1.amazonaws.com/0/donations", {
          method : "DELETE",
          headers : {
            Authorization : HWData.token
          },
          body: JSON.stringify({
            email : HWData.userEmail,
            id : HWData.current[HWData.edit].id,
            donation_id : event.target.dataset.id,
            timestamp : event.target.dataset.timestamp
          })
        })
        .then((res) => {
          if (401 === res.status) {
            alert("You're authentication has expired. Please login again.");
            setHWData({page: "login", token: null, userEmail: "", userPassword : "", loading: false});
          }
          else if (200 === res.status) {
            res.json().then((data) => {
            
              let newDate = new Date();
              
              
              let withNew = [{
                id : newDonationId(),
                user_id : user_id,
                amount : "",
                timestamp : (newDate.getTime() / 1000)
              }];
              
              if (data.donations[user_id]) {                        
                withNew = [ ...data.donations[user_id], {
                  id : newDonationId(),
                  user_id : user_id,
                  amount : "",
                  timestamp : (newDate.getTime() / 1000)
                } ].reverse();
              }
  
              const donations = { ...data.donations, [user_id] : withNew };
            
              setHWData({
                ...HWData, 
                donations : donations,
                loading : false
              }); 
            })
            .catch((err) => {
              console.log(err);
            })        
          }
        })
        .catch((err) => {
          console.log(err);
        });     
    }
  };
    
  const saveDonation = (event) => {
    try {
      HWData.donations[user_id].reduce((prev, donation) => {
        if (donation.id === event.target.dataset.id) {
          donation.amount = donation.amount.replace(/[^-\d.]/, '');
          if (!(parseFloat(donation.amount) > 0.00)) {
            throw new Error("Please enter a meaninful positive donation amount");
          }
          
          if (String(donation.timestamp).match(/\//)) {
            let parts = donation.timestamp.split('/');
            let date  = new Date(parts[2] + '-' + parts[0] + '-' + parts[1]);
            if ('Invalid Date' === String(date)) { throw new Error('Invalid Date'); }
            donation.timestamp = parseInt(date.getTime() / 1000);            
          }   
          else {
            donation.timestamp = parseInt(donation.timestamp);
          }
          
          // set screen to "laoding" gif
          setHWData({ ...HWData, page: "home", search: "", loading: true});
          
          fetch( "https://6rqtwb8h2f.execute-api.us-east-1.amazonaws.com/0/donations", {
            method : "POST",
            headers : {
              Authorization : HWData.token
            },
            body: JSON.stringify({
              email : HWData.userEmail,
              id : HWData.current[HWData.edit].id,
              update : donation
            })
          })
          .then((res) => {
            if (401 === res.status) {
              alert("You're authentication has expired. Please login again.");
              setHWData({page: "login", token: null, userEmail: "", userPassword : "", loading: false});
            }
            else if (200 === res.status) {
              res.json().then((data) => {
              
                let newDate = new Date();
                
                let withNew = [{
                  id : newDonationId(),
                  user_id : user_id,
                  amount : "",
                  timestamp : (newDate.getTime() / 1000)
                }];

                if (data.donations[user_id]) {                        
                  withNew = [ ...data.donations[user_id], {
                    id : newDonationId(),
                    user_id : user_id,
                    amount : "",
                    timestamp : (newDate.getTime() / 1000)
                  } ].reverse();
                }
    
                const donations = { ...data.donations, [user_id] : withNew };
              
                setHWData({
                  ...HWData, 
                  donations : donations,
                  loading : false
                }); 
              })
              .catch((err) => {
                console.log(err);
              })        
            }
          })
          .catch((err) => {
            console.log(err);
          });     
        }
      }, {});
    }
    catch (e) {
      alert(e);
    }
  };
  

  const existing_row = (entry, index) => {
    let display_date = HWData.donations[user_id][index].timestamp;

    if (!String(HWData.donations[user_id][index].timestamp).match(/\//)) {
      const date         = new Date(HWData.donations[user_id][index].timestamp * 1000);
      display_date = (date.getMonth()+1) + "/" + date.getDate() + "/" + date.getFullYear();      
    }
      
    return (
      <div className="row" style={{"marginTop":"10px"}} key={index}>
        <div className="col-md-4">
          <input type="text" className="form-control" value={display_date}
          placeholder="Date as mm/dd/yyyy" data-key="timestamp" data-id={entry.id}
          onChange={updateDonation}
          readOnly={"" !== HWData.donations[user_id][index].amount} />
        </div>
        <div className="col-md-4">
          <input type="text" className="form-control" value={HWData.donations[user_id][index].amount}
          placeholder="Dollar Amount" data-key="amount" data-id={entry.id}
          onChange={updateDonation} />
        </div>
        <div className="col-md-4 d-md-flex justify-content-md-end d-grid gap-2">
          <button className="btn btn-sm btn-outline-danger" data-id={entry.id} onClick={deleteDonation} data-timestamp={HWData.donations[user_id][index].timestamp}>
            Delete
          </button>
          <button className="btn btn-sm btn-outline-success" data-id={entry.id} onClick={saveDonation}>
            Save
          </button>
        </div>
      </div>        
    );
  };
  
  const existing_donations = () => {
    if (HWData.donations[user_id]) {
      return HWData.donations[user_id].map((donation, index) => existing_row(donation, index));
    }
    else {
      return "";
    }
  };
    
  return (
    <>
      <div className="row">
        <div className="col">
          <h3>Donations from {HWData.current[HWData.edit].name}</h3>
        </div>
      </div>      
      
      {existing_donations()}
    </>
  );
}

export default EditDonations;